import React from 'react';
import Button from './Button';
import colors from '../../utils/variables';
import contrat from '../../assets/pdf/contrat.pdf';

const Download = () => {
  const handleDownload = (e) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = contrat; 
    link.download = 'contrat.pdf'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      text="Télécharger le PDF"
      link="#"
      logo="fa-solid fa-download"
      textcolor={colors.lightBlue}
      background={colors.blue}
      onClick={handleDownload}
    />
  );
};

export default Download;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import colors from '../utils/variables';
import Logo from './Logo';
import Icon from './widgets/Icon';
import styled, { keyframes } from 'styled-components';
import MenuButton from './widgets/MenuButton';
import { useMediaQuery } from 'react-responsive';
import { media, breakpoints } from './../utils/variables';

const fadeIn = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`;

const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  top: 0;
  padding: 0 1rem;
  position: sticky;
  opacity: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  border-bottom: 1px solid rgba(216, 232, 242, 0.1);
  animation: ${fadeIn} 1s forwards;
  animation-delay: 1.5s;
  z-index: 100;
`;

const ContainerDesk = styled.nav`
  display: flex;
  top: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ContainerMobile = styled.nav`
  display: flex;
  flex-direction: column;
  background: black;
  height: 40vh;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 3rem 0;
  width: 100%;
  animation: ${fadeIn} 0.5s forwards;
  ${media.tabletteVerticale`
  height: 50vh;
  `}
  ${media.mobile`
   height: 80vh;
`}
`;

const StyledLink = styled(Link)`
  font-size: 1.5rem;
  display: flex;
  text-decoration: none;
  color: ${colors.lightBlue};
  &:hover {
    color: ${colors.blue};
    transform: scale(1.1);
    transition: transform 0.2s;
  }
  ${media.laptop`
    font-size: 1.3rem;
  `}
  ${media.tabletteLandscape`
    font-size: 1rem;
  `}
`;

const StyledA = styled.a`
  display: flex;
  gap: 2rem;
`;

const MiddleLinks = styled.div`
  display: flex;
  gap: 2rem;
  ${media.tabletteLandscape`
  gap: 1rem;
  `}
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 2rem;
  margin-right: 5rem;
  ${media.tabletteLandscape`
  margin-right: 1rem;
  gap: 1rem;
  `}
  ${media.tabletteVerticale`
  font-size: 1rem;
  gap: 3rem;
`}
`;

const MobileMenuButton = styled.div`
  display: none;

  ${media.tabletteLandscape`
    display: block;
    cursor: pointer;
  `}
`;
function Header() {
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tabletteVerticale})` });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuLinkClick = () => {
    if (isTabletOrMobile) {
      setIsMenuOpen(false); 
      
    }
  };

  return (
    <NavContainer>
      <ContainerDesk>
        <StyledA href="/#accueil">
          <Logo />
        </StyledA>
        {isTabletOrMobile && (
          <MobileMenuButton>
            <MenuButton menuOpen={isMenuOpen} toggleMenu={handleMenuToggle} />
          </MobileMenuButton>
        )}
        {!isTabletOrMobile ? (
          <>
            <MiddleLinks>
              <StyledLink to="/lieu" onClick={handleMenuLinkClick}>Lieu</StyledLink>
              <StyledLink to="/guinguette" onClick={handleMenuLinkClick}>Guinguette</StyledLink>
              <StyledLink to="/tarifs" onClick={handleMenuLinkClick}>Tarifs</StyledLink>
              <StyledLink to="/cours" onClick={handleMenuLinkClick}>Cours d'escalade</StyledLink>

              <StyledLink to="/actus" onClick={handleMenuLinkClick}>Actus</StyledLink>
              <StyledLink to="/localisation" onClick={handleMenuLinkClick}>Nous trouver</StyledLink>
            </MiddleLinks>
            <SocialIcons>
              <Icon logo={'fa-solid fa-user'} link={'https://boutique.45escalade.com/espace-client/connexion'} />
              <Icon logo={'fa-brands fa-instagram'} link={'https://www.instagram.com/45escalade'} />
              <Icon logo={'fa-brands fa-square-facebook'} link={'https://www.facebook.com/45escalade'} />
            </SocialIcons>
          </>
        ) : null}
      </ContainerDesk>
      {(isTabletOrMobile && isMenuOpen) ? (
        <ContainerMobile>
          <StyledLink to="/lieu" onClick={handleMenuLinkClick}>Le lieu</StyledLink>
          <StyledLink to="/guinguette" onClick={handleMenuLinkClick}>La Guinguette</StyledLink>
          <StyledLink to="/tarifs" onClick={handleMenuLinkClick}>Tarifs</StyledLink>
          <StyledLink to="/cours" onClick={handleMenuLinkClick}>Cours d'escalade</StyledLink>
          <StyledLink to="/actus" onClick={handleMenuLinkClick}>Actus</StyledLink>
          <StyledLink to="/localisation" onClick={handleMenuLinkClick}>Nous trouver</StyledLink>
          <SocialIcons>
            <Icon logo={'fa-solid fa-user'} link={'https://boutique.45escalade.com/espace-client/connexion/'} />  
            <Icon logo={'fa-brands fa-instagram'} link={'https://www.instagram.com/45escalade'} />
            <Icon logo={'fa-brands fa-square-facebook'} link={'https://www.facebook.com/45escalade'} />
          </SocialIcons>
        </ContainerMobile>
      ) : null}
    </NavContainer>
  );
}

export default Header;
import React, { useState } from 'react';
import styled from 'styled-components';
import kilter from '../assets/Kilter.jpeg';
import extérieur from '../assets/extérieur.jpg';
import musculation from '../assets/muscu.jpg';
import intérieur from '../assets/inté.jpg';
import Button from './widgets/Button';
import colors, { media } from '../utils/variables';



export const PicsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 100vh;
  ${media.tabletteVerticale`
    display: flex;
    flex-direction: column;
    height: 130vh;

`}
`;

export const PicContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 500px;
  ${media.tabletteLandscape`
    height: 400px;
  `}
 
  ${media.mobile`
  height: 300px;
  `}

`;

export const WallPic = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
  
  transition: transform 0.2s ease-in-out;
  
  &:hover {
    transform: scale(1.04);
    filter: brightness(50%);
  }
  
  ${media.tabletteLandscape`
  min-height: 400px;
  `}
  
  ${media.mobile`
    min-height: 300px;
    margin-top: -50px;
    object-position: 100% 50%;
  `}
`;
export const TextOverlay = styled.article`
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 20px;
  color: white;
  font-size: 18px;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  opacity: ${(props) => (props.isvisible ? 1 : 0)};
  transform: translateY(${(props) => (props.isvisible ? '0' : '20px')}); /* Décalage initial de 20px vers le bas */

  p {
    font-weight: 400;
  }

  ${media.mobile`
    opacity: 1;
    transform: translateY(0); 
    p{
      font-size: 17px;
    }
  `}
`;

export const BtnContainer = styled.div`
  margin-top: 0.5rem;
  width: 8rem;
  transition: opacity 0.2s ease-in-out;
  opacity: ${(props) => (props.isvisible ? 1 : 0)};
  ${media.mobile`
  opacity: 1;
`}
`;

const WallsPics = () => {
  const [isHoveredOuverture, setIsHoveredOuverture] = useState(false);
  const [isHoveredOutdoor, setIsHoveredOutdoor] = useState(false);
  const [isHoveredKilter, setIsHoveredKilter] = useState(false);
  const [isHoveredMusculation, setIsHoveredMusculation] = useState(false);



  return (
    <PicsContainer>
      <PicContainer
        onMouseEnter={() => setIsHoveredOuverture(true)}
        onMouseLeave={() => setIsHoveredOuverture(false)}
      >
        <WallPic src={intérieur} alt="Image bloc intérieur" />
        <TextOverlay  isvisible={isHoveredOuverture}>
          <h4>Espace bloc intérieur</h4>
          <p>400 m² de surface grimpable, de tous niveaux</p>
          <BtnContainer isvisible={isHoveredOuverture}>
            <Button
              text={'Le lieu'}
              link="lieu"
              textcolor={colors.lightBlue}
              background="tranparent"
              bordercolor={colors.lightBlue}
              padding={'0.5rem 2rem'}
            />
          </BtnContainer>
        </TextOverlay>
      </PicContainer>
  
      <PicContainer
        onMouseEnter={() => setIsHoveredOutdoor(true)}
        onMouseLeave={() => setIsHoveredOutdoor(false)}
      >
        <WallPic src={extérieur} alt="Image bloc extérieur" />
        <TextOverlay isvisible={isHoveredOutdoor}>
          <h4>Espace bloc extérieur</h4>
          <p>Pour profiter du beau temps de Marseille</p>
          <BtnContainer isvisible={isHoveredOutdoor}>
            <Button
              text={'Le lieu'}
              link="lieu"
              textcolor={colors.lightBlue}
              background="tranparent"
              bordercolor={colors.lightBlue}
              padding={'0.5rem 2rem'}
            />
          </BtnContainer>
        </TextOverlay>
      </PicContainer>
  
      <PicContainer
        onMouseEnter={() => setIsHoveredKilter(true)}
        onMouseLeave={() => setIsHoveredKilter(false)}
      >
        <WallPic src={kilter} alt="Image de kilterboard" />
        <TextOverlay isvisible={isHoveredKilter}>
          <h4>Kilter Board</h4>
          <p>La toute première KilterBoard de votre région</p>
          <BtnContainer isvisible={isHoveredKilter}>
            <Button
              text={'Le lieu'}
              link="lieu"
              textcolor={colors.lightBlue}
              background="tranparent"
              bordercolor={colors.lightBlue}
              padding={'0.5rem 2rem'}
            />
          </BtnContainer>
        </TextOverlay>
      </PicContainer>
  
      <PicContainer
        onMouseEnter={() => setIsHoveredMusculation(true)}
        onMouseLeave={() => setIsHoveredMusculation(false)}
      >
        <WallPic src={musculation} alt="Image de musculation" />
        <TextOverlay  isvisible={isHoveredMusculation}>
          <h4>Musculation</h4>
          <p>Un espace musculation orienté vers la pratique de l'escalade</p>
          <BtnContainer isvisible={isHoveredMusculation}>
            <Button
              text={'Le lieu'}
              link="lieu"
              textcolor={colors.lightBlue}
              background="tranparent"
              bordercolor={colors.lightBlue}
              padding={'0.5rem 2rem'}
            />
          </BtnContainer>
        </TextOverlay>
      </PicContainer>
    </PicsContainer>
  );
  }  
export default WallsPics;

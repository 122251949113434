import gsap from 'gsap';
import { React, useEffect } from 'react';
import styled from 'styled-components';
import { TextPlugin } from 'gsap/all';
import hero from '../../assets/hero.jpg';

import Button from '../widgets/Button';
import { media } from '../../utils/variables';

gsap.registerPlugin(TextPlugin);

 const HeroContainer = styled.main`
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url(${hero});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  background-position: center center;
  span {
    color: #00a9ef;
    font-weight: 200;
  }
`;

const TitleContainer = styled.div`
  padding-top: 90px;
  h1 {
    margin-top: -3rem;
  }
  h2 {
    margin-top: 6rem;
  }
  ${media.laptop`
    h1 {
      font-size: 120px;
    }
    h2 {
      font-size: 70px;
    }
  `}
  ${media.tabletteLandscape`
    h1 {
      font-size: 80px;
      margin-top: -2rem;
    }
    h2 {
      font-size: 40px;
      margin-top: 2rem;

    }
  `}
  ${media.tabletteVerticale`
    padding-top: 60px;
    h1 {
      font-size: 80px;
      margin-top: -1rem;

    }
    h2 {
      font-size: 40px;
    }
    `}
    ${media.mobile`
    h1 {
      font-size: 50px;
    }
    h2 {
      margin-top: 6rem;
      font-size: 30px;
      font-weight: 200;
    }
    `}
    ${media.iphoneSE`
    h2{
      margin-top: 2rem;
    }
  ` }
    ${media.iphone13`
    h2{
      margin-top: 2rem;
    }
  `}
`;
const TitleText = styled.div`
  position: relative;
  margin-left: 100px;
  ${media.tabletteLandscape`
    margin-left: 50px;
  `}
  ${media.tabletteVerticale`
    margin-left: 45px;
  `}
  ${media.mobile`
    margin-left: 15px;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 8rem;
  position: absolute;
  left: 50vw;
  transform: translateX(-50%);
  bottom: 10rem;
  ${media.mobile`
  flex-direction: column-reverse;
`}
`;

const Hero = () => {
  useEffect(() => {
    const titleOne = document.querySelector('.titleOne');
    const titleTwo = document.querySelector('.titleTwo');
    const titleThree = document.querySelector('.titleThree');
    const button = document.querySelector('.button');

    const tl = gsap.timeline();
    tl.fromTo(titleOne, { opacity: 0, y: 40 }, { opacity: 1, y: 0, duration: 0.5, ease: 'power2.inOut' }, 2.4);
    tl.fromTo(titleTwo, { opacity: 0, y: 40 }, { opacity: 1, y: 0, duration: 0.5, ease: 'power2.out' }, 2.8);
    tl.fromTo(titleThree, { opacity: 0, y: 40 }, { opacity: 1, y: 0, duration: 0.5, ease: 'power2.out' }, 3);
    tl.fromTo(button, { opacity: 0, y: 80 }, { opacity: 1, y: 0, duration: 0.5, ease: 'power2.out' }, 4);

    // function alternateAnimation() {
    //   tl.to('#dynamicText', { duration: 2, text: 'extérieur', delay: 3.5 }).to('#dynamicText', { duration: 2, text: 'intérieur', delay: 3, onComplete: alternateAnimation });
    // }
    // alternateAnimation();

    function handleScroll() {
      const scrollY = window.scrollY;
      const parallaxOne = -scrollY * 1.2;
      const parallaxTwo = -scrollY * 0.5;
      const parallaxThree = -scrollY * 0.2;

      gsap.to(titleOne, { duration: 0.5, y: parallaxOne });
      gsap.to(titleTwo, { duration: 0.5, y: parallaxTwo });
      gsap.to(titleThree, { duration: 0.5, y: parallaxThree });
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <HeroContainer id="accueil">
      <TitleContainer>
        <TitleText className="titleOne">
          <h1>45°</h1>
        </TitleText>
        <TitleText className="titleTwo">
          <h1>ESCALADE</h1>
        </TitleText>
        <TitleText className="titleThree">
          <h2>
            {/* Bloc <span id="dynamicText">intérieur</span>, Sauna */}
            <span>Grimpe</span> et guinguette <br />
            en plein coeur de <span>Marseille</span>
          </h2>
        </TitleText>
      </TitleContainer>
      <ButtonContainer className="button">
        <Button text={'Horaires'} link="#horaires" logo="fa-solid fa-arrow-down" />
        <Button text={'Boutiques'} link="https://boutique.45escalade.com" logo="fa-solid fa-cart-shopping" />
      </ButtonContainer>
    </HeroContainer>
  );
};

export default Hero;

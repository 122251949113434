import React from 'react';
import styled from 'styled-components';
import Map from '../widgets/Map';
import colors, { media } from '../../utils/variables';
import { Helmet } from 'react-helmet-async';

const Container = styled.div``;
const MapContainer = styled.div`
  padding: 2rem;
  h1 {
    text-align: center;
    font-size: 60px;
    padding: 2rem 0rem;
    ${media.laptop`
      font-size: 40px;
    `}
  }
  strong{
    color: ${colors.blue};
  }
  p{
    color: ${colors.lightBlue};
    font-weight: 300;
    padding: 2rem 1rem;
    text-align: justify;
  }
`;


const Localisation = () => {
  return (
    <>
    <Helmet>
    <title>Localisation</title>
    <meta name="description" content="Consultez nos horraires et localisation"></meta>
    <link rel='canonical' href='/localisation'></link>
  </Helmet>
    <Container id="localisation">
      <MapContainer>
        <h1>Localisation</h1>
        <Map />
        <p>
          <strong>En métro:</strong>  M1 Saint Just (puis 500m à pieds). <br /> <strong>En bus :</strong> Ligne 72.  <br /><strong>Levélo :</strong> Station Saint Just  – Le Dôme.<br /> <strong>En voiture :</strong> environ 20min depuis Castellane ou le Vieux port, 10min depuis Saint Charles. <br />
          <strong>Vélo perso :</strong> un parking à vélo est à votre disposition dans la cour.
        </p>

      </MapContainer>
    </Container>
    </>
  );
};

export default Localisation;

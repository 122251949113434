import React from 'react';
import styled from 'styled-components';
import video from '../../assets/video.MOV';

const VideoContainer = styled.div`
  width: 100%;
  overflow: hidden;
  height: auto;
`;

const ResponsiveVideo = styled.video`
  width: 100%;
`;

const Video = () => {
  return (
    <VideoContainer>
      <ResponsiveVideo autoPlay controls>
        <source src={video} type="video/mp4" alt="Vidéo de présentation" />
      </ResponsiveVideo>
    </VideoContainer>
  );
};

export default Video;

import React from 'react';
import styled from 'styled-components';
import logo from '../assets/smallLogoWhite.png';
import { media } from '../utils/variables';

const StyledLogo = styled.div`
  img {
    width: 80px;  
    height: auto;  
    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s; 
    }
    ${media.tabletteLandscape`
      width: 60px;
  `}
      `;

const Logo = () => {
  return (
    <StyledLogo>
      <img src={logo} alt="Logo" />
    </StyledLogo>
  );
};

export default Logo;

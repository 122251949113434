import React, { useState } from 'react';
import fete from '../assets/blue.jpg';
import détente from '../assets/detente.jpg';
import Button from './widgets/Button';
import colors, { media } from '../utils/variables';
import {BtnContainer, PicsContainer, TextOverlay, } from './WallsPics';
import styled from 'styled-components';

 const PicContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 500px;
  ${media.tabletteLandscape`
    height: 400px;
  `}
  ${media.tabletteVerticale`
    height: 400px;
  `}
  ${media.mobile`
  height: 260px;
  `}

`;
const WallPic = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.04);
    filter: brightness(50%);

  }
  ${media.tabletteLandscape`
    height: 400px;
`}
  ${media.tabletteVerticale`
    height: auto;
    min-height: 400px;

    object-position: center center;
`}
  ${media.mobile`
    min-height: 300px;
    filter: brightness(80%);
    margin-top: -50px;

  `}

`;

const ChillPics = () => {
  const [isHoveredGuinguette, setIsHoveredGuinguette] = useState(false);
  const [isHoveredActus, setIsHoveredActus] = useState(false);
  return (
    <PicsContainer>
      <PicContainer onMouseEnter={() => setIsHoveredGuinguette(true)}
        onMouseLeave={() => setIsHoveredGuinguette(false)}>
        <WallPic src={détente} alt="Image de détente" />
        <TextOverlay isvisible={isHoveredGuinguette} >
          <h4>Espace détente</h4>
          <p>Ambiance chill à la marseillaise</p>
          <BtnContainer isvisible={isHoveredGuinguette}>
            <Button text={'Guinguette'} link="guinguette" textcolor={colors.lightBlue} background="tranparent" bordercolor={colors.lightBlue} padding={"0.5rem 1rem"}/>
          </BtnContainer>
        </TextOverlay>
      </PicContainer>
      <PicContainer  onMouseEnter={() => setIsHoveredActus(true)}
        onMouseLeave={() => setIsHoveredActus(false)}>
        <WallPic src={fete} alt="Image de fête" />
        <TextOverlay isvisible={isHoveredActus}>
          <h4>Événements</h4>
          <p>Partage d'idées, événements culturels et associatifs.</p>
          <BtnContainer isvisible={isHoveredActus}>
            <Button text={'Actus'} link='actus' textcolor={colors.lightBlue} background="tranparent" bordercolor={colors.lightBlue} padding={"0.5rem 2.2rem"}/>
          </BtnContainer>
        </TextOverlay>
      </PicContainer>
    </PicsContainer>
  );
};

export default ChillPics;

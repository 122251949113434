import React, { useState } from 'react';
import styled from 'styled-components';
import colors, { media } from './../utils/variables';

const Border = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  max-width: 400px;
  color: white;
  border-radius: 3%;
  height: 500px;
  padding: 1rem;
  width: 100%;
  overflow: hidden;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: ${(props) => (props.isflipped ? 'rotateY(180deg)' : 'rotateY(0)')};
  ${media.mobile`
  height: 450px;
`}
 
`;

const BtnContainer = styled.div`
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.4);
  max-width: 50%;
  border-radius: 10px;
  margin: auto;
  margin-top: 110%;
  padding: 15px;
  cursor: pointer;
  overflow: hidden;
  ${media.mobile`
  padding: 15px 20px;
  text-align: center;
  margin-top: 140%;
`}
  &:hover {
    background: rgba(255, 255, 255, 0.3);
    color: white;

  }
  transition: transform 0.6s;
  transform-style: preserve-3d;
  transform: ${(props) => (props.isflipped ? 'rotateY(180deg)' : 'rotateY(0)')};
  a{
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    font-weight: 900;
    cursor: pointer;
    text-align: center;
    padding: 1rem;
      ${media.mobile`
      font-size: 15px;
      padding: 0;
    `}
  }

`;

const BtnText = styled.p`
  color: rgba(255, 255, 255, 1);
  font-size: 25px;
  font-weight: 900;
  text-align: center;
  ${media.mobile`
  font-size: 20px;
`}


`;

const LightEffect = styled.div.attrs((props) => ({
  style: {
    opacity: props.ishovered ? 0.5 : 0,
    display: props.ishovered ? 'block' : 'none',
    left: `${props.mousex}px`,
    top: `${props.mousey}px`,
  },
}))`
  position: absolute;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 70%);
  width: 700px;
  height: 700px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
`;
const FrontContent = styled.div.attrs((props) => ({
  style: {
    display: props.isflipped ? 'none' : 'block',
    position: 'absolute',
    top: '20%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    opacity: props.isflipped ? 0 : 1,
    transition: 'opacity 0.6s ease-in-out',
  },
}))`

transform: ${(props) => (props.isflipped ? 'rotateY(180deg)' : 'rotateY(0)')};
p {
  font-size: 35px;
  text-transform: uppercase;
  color: ${colors.lightBlue};
  font-weight: 900;
  text-align: center;
  ${media.mobile`
  font-size: 25px;
`}
}
`;
const BackContent = styled.div.attrs((props) => ({
  style: {
    display: props.isflipped ? 'block' : 'none',
  },
}))`
  position: absolute;
  top: 1%;
  left: 10%; 
  right: 10%; 
  bottom: 10%; 
  padding: 1rem;
  display: flex; 
  justify-content: center;
  align-items: center;
  transform: ${(props) => (props.isflipped ? 'rotateY(180deg)' : 'rotateY(0)')};
  p {
    font-size: 35px;
    color: ${colors.lightBlue};
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    ${media.mobile`
    font-size: 25px;
  `}
  }
  strong {
    color: ${colors.blue};
  }
`;



const Card = ({ text, backText, backContent, frontContent }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isflipped, setIsFlipped] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showContent, setShowContent] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

const handleMouseMove = (e) => {
  const borderRect = e.currentTarget.getBoundingClientRect();
  const offsetX = isflipped ? borderRect.right - e.clientX : e.clientX - borderRect.left;
  const offsetY = e.clientY - borderRect.top;

  setMousePosition({ x: offsetX, y: offsetY });
};

  const handleCardClick = () => {
    setIsFlipped(!isflipped);
       setShowContent(false);
    setTimeout(() => {
      setShowContent(true);
    }, 200);
  };

  return (
    <Border onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} isflipped={isflipped}>
      <LightEffect ishovered={isHovered} mousex={mousePosition.x} mousey={mousePosition.y} />
      <FrontContent isflipped={isflipped} style={{ opacity: showContent ? 1 : 0 }}>
        {frontContent}
      </FrontContent>
      <BtnContainer isflipped={isflipped} onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove}>
      {isflipped ? (
        <a href="https://boutique.45escalade.com" target="_blank" rel="noopener noreferrer" onClick={handleCardClick}>
          {backText}
        </a>
      ) : (
        <BtnText onClick={handleCardClick}>{text}</BtnText>
      )}
    </BtnContainer>
      <BackContent isflipped={isflipped} style={{ opacity: showContent ? 1 : 0 }}>
        {backContent}
      </BackContent>
    </Border>
  );
};

export default Card;
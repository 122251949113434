import React from 'react';
import styled from 'styled-components';
import colors from '../../utils/variables';

const MenuButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.open .menu-btn__burger {
    transform: translateX(-40px);
    background: transparent;
    box-shadow: none;
  }

  &.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(25px, -25px);
    background: ${colors.blue}; 

  }

  &.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(25px, 25px);
    background: ${colors.blue}; 

  }
`;

const Burger = styled.div`
  width: 30px;
  height: 3px;
  background: ${colors.lightBlue}; 
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
  transition: all 0.2s ease-in-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: ${colors.lightBlue}; 
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
  }

  &::before {
    transform: translateY(-10px);
  }

  &::after {
    transform: translateY(10px);
  }
`;


const MenuButton = ({ menuOpen, toggleMenu }) => {
  return (
    <MenuButtonWrapper className={menuOpen ? 'open' : ''} onClick={toggleMenu}>
      <Burger className="menu-btn__burger" />
    </MenuButtonWrapper>
  );
};

export default MenuButton;
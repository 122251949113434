import React from 'react';
import styled from 'styled-components';
import colors, { media } from '../../utils/variables';
import SectionTitle from '../widgets/SectionTitle';
import WallsPics from '../WallsPics';


const WallsContainer = styled.section`
  background: black;
  color: black;
  height: 140vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
      ${media.desktop`
    height: 160vh;
  `}
  ${media.tabletteVerticale`
    height: 170vh;
`}
  ${media.mobile`
    height: 190vh;
`}
`;

const SectionTitleContainer = styled.div`
  text-align: center;
  h3 {
    font-size: 140px;
    font-weight: 200;
    color: ${colors.lightBlue};
    ${media.tabletteVerticale`
      font-size: 100px;
    `}
    ${media.mobile`
      font-size: 60px;
    `}
  }
`;
const TextContainer = styled.div`
  text-align: center;
  p {
    font-size: 30px;
    font-weight: 200;
    color: ${colors.lightBlue};
    margin: 3rem;
    ${media.tabletteLandscape`
      font-size: 25px;
  `}
  ${media.tabletteVerticale`
    font-size: 20px;
  `}
  }
  strong {
    color: ${colors.blue};
  }
`;


const Walls = () => {
  return (
    <WallsContainer>
      <SectionTitleContainer>
        <SectionTitle title={'LA GRIMPE'} />
      </SectionTitleContainer>
      <TextContainer>
        <p>
          Votre <strong>progression</strong> est notre priorité, une <strong>escalade</strong> exigeante et un entraînement performant, c'est ce que nous mettons en oeuvre pour vous permettre
          d'atteindre vos <strong>objectifs</strong>
        </p>
      </TextContainer>
      <WallsPics />
    </WallsContainer>
  );
};

export default Walls;

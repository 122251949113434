import React from 'react';
import styled from 'styled-components';
import crimp from '../../assets/crimp.jpg';
import { TextContainer } from './Tarifs';
import { Title } from './Guinguette';
import colors, { media } from '../../utils/variables';
import Video from '../widgets/Video';
import { Helmet } from 'react-helmet-async';


const Container = styled.div`


`;
const Background = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${crimp});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 150vh;
  background-position: center center;
  background-attachment: fixed;
  ${media.desktop`
  height: 180vh;
`}
${media.laptop`
  height: 160vh;
`}
${media.tabletteLandscape`
  height: 180vh;
`}
${media.tabletteVerticale`
  height: 130vh;
`}
  ${media.mobile`
  background-position: center right;
  height: 180vh;
`}
  span {
    color: #00a9ef;
    font-weight: 200;
  }

`;
const ModifiedTextContainer = styled(TextContainer)`
  padding: 2rem 7rem 1rem 7rem;
  background: rgba(0, 0, 0, 0.5);
  height: 94%;
  ${media.laptop`
    padding: 2rem 5rem 1rem 5rem;
    height: 94%;
  `}
  ${media.tabletteLandscape`
    padding: 2rem 3rem 1rem 3rem;
    height: 95%;
  `}
  ${media.tabletteVerticale`
    height: 94%;
  `}
  ${media.mobile`
    padding: 2rem 1rem 1rem 1rem;
    height: 94%;
  `}
  p {
    width: 80%;
    text-align: justify;
 
  ${media.laptop`
    font-size: 25px;
  `}
  ${media.tabletteLandscape`
    font-size: 20x;
  `}
  ${media.tabletteVerticale`
    font-size: 20px;
    width: 70%;
  `}
  ${media.tabletteVerticale`
  font-size: 18px;
  width: 90%;
`}
  }
`;

const SubTitle = styled.h3`
  color: ${colors.blue};
  font-size: 30px;
  margin: 1rem 0rem;
  ${media.laptop`
    font-size: 25px;
`}
  ${media.tabletteVerticale`
    font-size: 20px;
  `}
  
`;
const SubText = styled.p`
  width: 60%;
  p{
    ${media.mobile`
      width: 100%;
      overflow-y: scroll;
    `}

  }
  a{
    color: ${colors.blue};
    text-decoration: underline;
    font-weight: 600;
  }

`;

const Lieu = () => {
  return (
    <>
    <Helmet>
    <title>Lieu</title>
    <meta name="description" content="Visitez notre salle."></meta>
    <link rel='canonical' href='/lieu'></link>
  </Helmet>
    <Container id="lieu">

      <Video controls>
        <source src={Video} type="video/mp4" alt="vidéo de présentation" />
      </Video>
    <Background >
      <Title>
        <h2>Le lieu / la salle d'escalade/ la grimpe</h2>
      </Title>


      <ModifiedTextContainer>
        <p>Au <strong>45°</strong>  vous pouvez trouver tout ce dont vous avez besoin pour <strong>découvrir</strong>  l’escalade et <strong>progresser</strong>  dans votre pratique à votre rythme ! </p>
        <SubTitle>
          <h3>L'escalade</h3>
        </SubTitle>
        <SubText>
          <p>
            L’espace d’escalade se divise en deux parties, l’espace <strong>intérieur</strong>  avec 400m² de surface grimpable et des panneaux de la <strong>dalle au dévers. </strong> Le <strong>mur extérieur</strong> est un fronton de 100m² qui reste à
            l’ombre toute la journée pour grimper à la <strong>fraîche</strong> ! Des nouveaux blocs vous attendent <strong>toutes les semaines</strong>  grâce à notre équipe d’ouvreurs chevronnée.
          </p>
        </SubText>
        <SubTitle>
          <h3>La Kilter board</h3>
        </SubTitle>
        <SubText>
          <p>
            Sans oublier le clou du spectacle, la première <a href="https://www.youtube.com/watch?v=TU4ozz3XiNY" target="_blank" rel="noopener noreferrer">Kilter board </a>
 du sud de la France ! Un panneau connecté avec des LED qui vous permet d’essayer des blocs ouverts préalablement sur
            l’application et de vous mesurer aux meilleurs mondiaux.
          </p>
        </SubText>
        <SubTitle>
          <h3>La musculation</h3>
        </SubTitle>
        <SubText>
          <p>
            Nous vous avons concocté un espace de musculation <strong>haut de gamme</strong> avec : Un espace dédié à l’entraînement en escalade : les poutres classiques de  <strong>Beastmaker</strong> , la poutre connectée <strong>Smartboard</strong>
            pour planifier et suivre votre <strong>progression.</strong>  Un espace dédié à la musculation pure avec des tapis, des haltères libres, une cage à squat et développé couché et un vélo pour le cardio !
          </p>
        </SubText>
        <SubTitle>
          <h3>Le sauna</h3>
        </SubTitle>
        <SubText>
          <p>Après une bonne séance de grimpe et de musculation, vous pouvez <strong>détendre </strong>vos muscles dans le sauna, accessible gratuitement avec la séance !</p>
        </SubText>
      </ModifiedTextContainer>
    </Background>
    </Container>
    </>
  );
};

export default Lieu;

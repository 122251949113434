import React from 'react';
import styled from 'styled-components';
import colors, { media } from '../../utils/variables';
import SectionTitle from '../widgets/SectionTitle';
import ChillPics from '../ChillPics';

const ChillsContainer = styled.section`
  background:  ${colors.lightBlue};
  color: black;
  height: 110vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
      ${media.desktop`
    height: 160vh;
  `}
  ${media.tabletteVerticale`
    height: 120vh;
  `}
  ${media.mobile`
    height: 120vh;
  `}
`;

const SectionTitleContainer = styled.div`
  text-align: center;
  h3 {
    font-size: 140px;
    margin-top: 5rem;
    font-weight: 200;
    color: black;
    ${media.desktop`
      margin-top: 12rem;
      `}
    ${media.laptop`
    font-size: 90px;
  `}
    ${media.tabletteVerticale`
    font-size: 70px;
  `}
    ${media.mobile`
      margin-top: 2rem;
      font-size: 40px;
  `}
  }
`;
const TextContainer = styled.div`
  text-align: center;
  p {
    font-size: 30px;
    font-weight: 200;
    color: black;
    margin: 3rem;
    ${media.tabletteLandscape`
    font-size: 25px;
`}
    ${media.tabletteVerticale`
    font-size: 20px;
`}
    ${media.mobile`
    font-size: 20px;
  `}
  }
  strong {
    color: ${colors.blue};
  }
`;


const Walls = () => {
  return (
    <ChillsContainer>
      <SectionTitleContainer>
        <SectionTitle title={'LA GUINGUETTE'} />
      </SectionTitleContainer>
      <TextContainer>
        <p>
          <strong>Sauna</strong>, pétanque, fléchette, apéro et <strong>événements culturels</strong>, vous trouverez à 45° un lieu de détente  <strong>convivial</strong> 
        </p>
      </TextContainer>
      <ChillPics />
    </ChillsContainer>
  );
};

export default Walls;

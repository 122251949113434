import React from 'react';
import styled from 'styled-components';
import enfant from '../../assets/enfant.png';
import colors, { media } from '../../utils/variables';
import { Helmet } from 'react-helmet-async';
import Download from '../widgets/Download';


const Background = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${enfant});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 190vh;
  background-attachment: fixed;
  background-position: center left;
  h1{
  text-align: center;
    ${media.laptop`
    font-size: 60px;
    padding: 2rem;

  `}
   ${media.mobile`
    font-size: 40px;
  `}
  }
  ${media.desktop`
    height: 120vh;
  `}
  span {
    color: #00a9ef;
    font-weight: 200;
  }
`;


export const TextContainer = styled.div`
  padding: 1rem 5rem 1rem 5rem;
  background: rgba(0, 0, 0, 0.3);
p{
  font-size: 30px;
  font-weight: 300;
  color: white;
  text-align: justify;
  ${media.tabletteLandscape`
  font-size: 20px;
  `}
}
strong{
  color: ${colors.blue};
  font-weight: 400;
}
`;

export const ModifiedText = styled.div`
  padding: 1rem 5rem 1rem 5rem;
  background: rgba(0, 0, 0, 0.3);
  margin: 5rem 2rem 5rem 40rem;
    ${media.desktop`
  margin: 5rem 2rem 5rem 20rem;
  `}
     ${media.tabletteLandscape`
  margin: 5rem 2rem 5rem 10rem;
  padding: 1rem 2rem 1rem 2rem;
  `}
      ${media.tabletteLandscape`
  margin: 5rem 2rem 5rem 5rem;
  `}
        ${media.mobile`
  margin: 2rem;
  `}
}
p{
  font-size: 30px;
  font-weight: 300;
  color: white;
  text-align: justify;
  ${media.tabletteLandscape`
  font-size: 20px;
  `}
     ${media.desktop`
  font-size: 20px;
  `}
}
strong{
  color: ${colors.blue};
  font-weight: 400;
}
  h2{
  color: ${colors.blue};
  font-weight: 400;
  padding: 1rem;
  text-align: center;
   ${media.laptop`
  font-size: 40px;
  `}
     ${media.tabletteLandscape`
  font-size: 30px;
  `}
    ${media.mobile`
  font-size: 30px;
  `}
}
`;

const ButtonContainer = styled.div`
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;


const Cours = () => {
  return (
    <>
    
    <Helmet>
      <title>Tarifs</title>
      <meta name="description" content="Consultez nos tarifs."></meta>
      <link rel='canonical' href='/tarifs'></link>
    </Helmet>
      <Background>
       <h1>Cours d'escalade</h1>

        <TextContainer>
          <p>32 cours <strong>collectifs</strong> du 18 septembre 2024 au juin 2025, accès <strong>illimité</strong> de septembre à août de la saison en cours.</p>
        </TextContainer>
        <ModifiedText>
          <h2>Les crénaux:</h2>
          <p>
             10 - 11 ans: Mercredi 13h00 - 14h30. <br/><br/>
             12 - 14ans: Mercredi 14h45 - 16h15.<br/><br/>
             15 - 18ans: Mercredi 16h30 - 18h00.<br/>
          </p>
          <h2>les tarifs:</h2>
          <p>
            1 Enfant: Comptant 550€ ou 50€ mensualisé. <br/><br/>
            2 Enfants: Comptant 1000€ ou 45€ mensualisé. <br/><br/>
            3 Enfants: Comptant 1350€ ou 50€ mensualisé. <br/>
          </p>
            <ButtonContainer>
              <Download />
            </ButtonContainer>
          </ModifiedText>
      </Background>
    </>
  );
};

export default Cours;

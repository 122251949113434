import React from 'react';
import Card from '../Card';
import styled from 'styled-components';
import pink from '../../assets/pink.jpg';
import colors, { media } from '../../utils/variables';
import { Helmet } from 'react-helmet-async';


 const Background = styled.main`
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${pink});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 160vh;
  background-position: center center;
  background-attachment: fixed;
  span {
    color: #00a9ef;
    font-weight: 200;
  }
  ${media.tabletteLandscape`
    height: 270vh;
  `}
  ${media.tabletteVerticale`
  height: 280vh;
`}
  ${media.mobile`
  height: 390vh;
`}
`;
const CardsContainer = styled.main`
  padding: 5rem 8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  ${media.desktop`
    padding: 3rem 15rem;
    gap: 3rem;
`}
  ${media.laptop`
    padding: 3rem 5rem;
    gap: 3rem;
`}
${media.tabletteVerticale`
  padding: 3rem 3rem;
  gap: 2rem;
`}
${media.tabletteLandscape`
  padding: 3rem 3rem;
  gap: 2rem;
  justify-content: center;
`}
`;
export const TextContainer = styled.div`
  padding: 1rem 5rem 1rem 5rem;
  background: rgba(0, 0, 0, 0.3);
p{
  font-size: 30px;
  font-weight: 300;
  color: white;
  text-align: justify;
  ${media.tabletteLandscape`
  font-size: 20px;https://boutique.45escalade.com/espace-client/connexion
  `}
}
strong{
  color: ${colors.blue};
  font-weight: 400;
}
`;


const Tarifs = () => {
  return (
    <>
    
    <Helmet>
      <title>Tarifs</title>
      <meta name="description" content="Consultez nos tarifs."></meta>
      <link rel='canonical' href='/tarifs'></link>
    </Helmet>
      <Background>

        <CardsContainer>
        
        <Card
        text="Tarifs"
        backText="Boutiques"
        backContent={
          <p> normal<br /> <strong>16€</strong><br /><br />
           Tarif réduit <br /><strong> 14€</strong></p>
        }
        frontContent={<p>Séances</p>}
      />
       <Card
        text="Tarifs"
        backText="Boutiques"
        backContent={
          <p> normal<br /> <strong>135€</strong><br /><br />
           Tarif réduit <br /><strong> 110€</strong><br /><br />
           happy hours <br /><strong> 95€</strong></p>
        }
        frontContent={<p>Cartes <br />10 séances</p>}
      />
       <Card
        text="Tarifs"
        backText="Boutiques"
        backContent={
          <p>Mensuel reconductible<br /> <strong>50€</strong><br /><br />
           happy hours <br /><strong> 30€</strong><br /><br />
          Annuel <br /><strong> 500€</strong></p>
        }
        frontContent={<p>Abonnement</p>}
      />
       <Card
        text="Tarifs"
        backText="Boutiques"
        backContent={
          <p>1 enfant <br /><strong>550€</strong><br /><br />
          2 enfants <br /><strong> 1000€</strong><br /><br />
          3 enfants <br /><strong> 1350€</strong></p>
        }
        frontContent={<p>Cours</p>}
      />
        </CardsContainer>

        <TextContainer>
          <p> Le tarif <strong>réduit</strong> est accessible aux <strong>étudiants</strong> post bac, aux <strong>enfants</strong>  de 12 à 17ans, aux personnes
              en <strong>recherche d'emploi</strong>, aux personnes en <strong>situationde handicap</strong> , aux <strong>moniteurs.rices d’escalade et ouvreur.ses.</strong> <br/>
              Le tarif <strong>Happy Hours</strong> est applicable pour toute personne venant du lundi au vendredi <strong>avant 16h et après 20h 30</strong>, et le <strong>samedi et dimanche dès 19h.</strong></p>
        </TextContainer>
      </Background>
    </>
  );
};

export default Tarifs;

import React from 'react';
import styled from 'styled-components';



const Container = styled.main`
  padding: 5rem;
  text-align: center;
  h2{
    text-decoration: underline;
  }
`;

const Erreur = () => {
  return (
<Container>
  <h1>Erreur 404</h1>
  <h2><a href="/#accueil">Revenir à 45 Escalade</a></h2>
</Container>
  );
};

export default Erreur;
import React, { useEffect, useState } from 'react';
import Hero from '../ecrans/Hero';
import Opening from '../ecrans/Opening';
import Staf from '../ecrans/Staf';
import Walls from '../ecrans/Walls';
import Chill from '../ecrans/Chill';
import Loader from '../widgets/Loader';


const Accueil = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer); 
  }, []);
  return (
    <>
       {loading && 
        <Loader /> }
        <Hero/>
        <Opening />
        <Walls />
        <Chill />
        <Staf />
    </>
  );
};

export default Accueil;

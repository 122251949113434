import React from 'react';
import styled from 'styled-components';
import colors from '../../utils/variables';

const StyledButton = styled.a`
  background: ${(props) => props.background || 'transparent'};
  color: ${(props) => props.textcolor || colors.lightBlue};
  border: 1px solid ${(props) => props.bordercolor || colors.blue};
  padding: ${(props) => props.padding || '1rem 2rem'};
  border-radius: 5px;
  font-size: 1.2rem;
  font-family: 'Outfit', sans-serif;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  backdrop-filter: blur(5px);
  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s;
  }
`;

const ButtonText = styled.div`
  color: ${(props) => props.textcolor || colors.lightBlue};
`;

const Button = ({ text, link, logo, textcolor, background, bordercolor, padding, onClick}) => {
  return (
    <StyledButton href={link} textcolor={textcolor} background={background} bordercolor={bordercolor} onClick={onClick} padding={padding}>
      <ButtonText textcolor={textcolor}>{text}</ButtonText>
      {logo ? <i className={logo} /> : "" }
    </StyledButton>
  );
};

export default Button;

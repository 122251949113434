import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import Accueil from "./components/pages/Accueil.jsx";
import Lieu from './components/pages/Lieu.jsx';
import Tarifs from './components/pages/Tarifs.jsx';
import Actus from './components/pages/Actus.jsx';
import Erreur from './components/pages/Erreur.jsx';
import GlobalStyle from './utils/GlobalStyle'
import Guinguette from './components/pages/Guinguette.jsx';
import Localisation from './components/pages/Localisation.jsx';
import Cgv from './components/pages/Cgv.jsx';
import Ri from './components/pages/Ri.jsx';
import { HelmetProvider } from 'react-helmet-async';
import Cours from './components/pages/Cours.jsx';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>

    <BrowserRouter>
      <GlobalStyle />
        <Header />
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/lieu" element={<Lieu />} />
          <Route path="/guinguette" element={<Guinguette />} />
          <Route path="/tarifs" element={<Tarifs />} />
          <Route path="/cours" element={<Cours />} />
          <Route path="/actus" element={<Actus />} />
          <Route path="/localisation" element={<Localisation />} />
          <Route path="/conditions" element={<Cgv />} />
          <Route path="/règlements" element={<Ri />} />
          <Route path="*" element={<Erreur />} />
        </Routes>
        <Footer />
    </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

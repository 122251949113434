import React from 'react';
import styled from 'styled-components';
import yellow from '../../assets/yellow.jpg';
import { TextContainer } from './Tarifs';
import colors, { media } from '../../utils/variables';

const Background = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${yellow});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 110vh;
  background-attachment: fixed;
  background-position: center center;
  ${media.desktop`
    height: 220vh;
  `}
   ${media.laptop`
    height: 120vh;
  `}
  span {
    color: #00a9ef;
    font-weight: 200;
  }
`;

export const Title = styled.div`
  background: rgba(0, 0, 0, 0.5);
  text-align: left;
  padding-left: 7rem;
  padding-top: 2rem;
  ${media.laptop`
    padding-left: 5rem;
  `}
  ${media.tabletteLandscape`
    padding-left: 3rem;
  `}
  ${media.mobile`
    text-align: center;
    padding: 1rem 0 0 1rem;;
  `}
  h2 {
    color: ${colors.blue};
    font-weight: 400;
    text-transform: uppercase;
    ${media.desktop`
      font-size: 60px;
    `}
    ${media.laptop`
      font-size: 50px;
    `}
    ${media.tabletteLandscape`
      font-size: 40px;
    `}
    ${media.tabletteVerticale`
      font-size: 30px;
    `}
  }
`;

export const ModifiedTextContainer = styled(TextContainer)`
  padding: 2rem 7rem 1rem 7rem;
  background: rgba(0, 0, 0, 0.5);
  height: 92%;
  ${media.laptop`
    padding: 2rem 5rem 1rem 5rem;
    height: 94%;
  `}
  ${media.tabletteLandscape`
    padding: 2rem 3rem 1rem 3rem;
    height: 93%;
  `}
  ${media.tabletteVerticale`
    height: 94%;
  `}
  ${media.mobile`
    padding: 2rem 1rem 1rem 1rem;
    height: 95%;
  `}
  p {
    width: 50%;
    text-align: justify;
 
  ${media.laptop`
    font-size: 25px;
  `}
  ${media.tabletteLandscape`
    font-size: 20x;
    width: 60%;
  `}
  ${media.tabletteVerticale`
    font-size: 20px;
    width: 70%;
  `}
  ${media.tabletteVerticale`
    font-size: 18px;
    width: 90%;
`}
  ${media.mobile`
    padding: 0rem 1rem;
    width: 95%;
  `}
  }
`;

const Guinguette = () => {
  return (

    <Background>
      <Title>
        <h2>La guinguette</h2>
      </Title>
      <ModifiedTextContainer>
        <p>
          Que vous soyez ou non <strong>grimpeur ou grimpeuse</strong>, petit ou grand, <strong>végé ou carnivore</strong> , la <strong>guinguette</strong> vous accueille au sein de notre salle
          d’escalade <strong>7j /7 </strong> de 10h30 à 23h en semaine et de 10h à 22h le week-end. <br />
          <br /> Vous pourrez y <strong>déguster</strong> des snacks et une boisson en
          <strong> extérieur</strong> ou en intérieur en admirant les <strong>prouesses</strong> de nos abonné.e.s, en faisant une <strong>pétanque</strong> ou une partie de <strong>fléchette</strong>
          . Vous pourrez y manger, y boire* à toutes heures de la journée en <strong>profitant</strong> du cadre. <br />
          <br />
          Chaque produit a été goûté et regoûté par nos papilles expertes (on s’est donné !) et est issu de <strong>producteurs locaux</strong> à la démarche <strong>raisonnée.</strong>
          <br />
          <br />
          Venez bien manger, bien boire, bien vous <strong>amuser</strong> avec nous ! On a le sens de <strong>l’hospitalité</strong> !
        </p>
      </ModifiedTextContainer>
    </Background>
  
  );
};

export default Guinguette;

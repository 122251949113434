// Importez votre image SVG
import { createGlobalStyle } from 'styled-components';
import colors from './variables';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    scroll-behavior: smooth;
  }
  body {
    font-family: 'Outfit', sans-serif;
    color: ${colors.lightBlue};
    background-color: black;
    user-select: none;
  }

  a{
    color: ${colors.lightBlue};
    text-decoration: none;

  }
  h1{
    font-size: 130px;
    font-weight: 400;
  }
  h2{
    font-size: 80px;
    font-weight: 200;
  }
  p{
    font-size: 20px;
    font-weight: 100;
  }
`;

export default GlobalStyle;

import React from 'react';
import styled from 'styled-components';
import colors from '../../utils/variables';
import SectionTitle from '../widgets/SectionTitle';
import Button from '../widgets/Button';
import Icon from '../widgets/Icon';
import { media } from '../../utils/variables';

const OpeningContainer = styled.section`
  background: ${colors.lightBlue};
  color: black;
  height: 120vh;
  position: relative;
     ${media.tabletteLandscape`
    height: 160vh;
  `}
       ${media.tabletteVerticale`
    height: 120vh;
  `}
  ${media.mobile`
    height: 140vh;
  `}
`;

const SectionTitleContainer = styled.div`
  h3 {
    position: absolute;
    left: 50vw;
    transform: translateX(-50%);
    margin-top: -90px;
    font-size: 250px;
    font-weight: 800;
  }
  ${media.laptop`
  h3{
    margin-top: -65px;
    font-size: 190px;
    }
  `}
  ${media.tabletteLandscape`
  h3{
    margin-top: -50px;
    font-size: 140px;
    }
  `}
  ${media.tabletteVerticale`
  h3{
    margin-top: -35px;
    font-size: 100px;
    }
  `}
  ${media.mobile`
  h3{
    margin-top: -20px;
    font-size: 60px;
    }
  `}
`;

const InfoContainer = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  width: 80%;
  height: 600px;
  position: absolute;
  left: 10%;
  transform: translateX(-50%);
  top: 55%;
  transform: translateY(-50%);
  ${media.tabletteLandscape`
    top: 45%;
    left: 5%;
    width: 90%;
  `}
  ${media.tabletteVerticale`
    border: none;
  `}
  ${media.mobile`
    top: 30%;
    margin-top: 5rem;
  `}
`;
const HoraireSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  h4 {
    margin-top: -35px;
    background: ${colors.lightBlue};
    font-size: 45px;
    font-weight: 200;
    ${media.laptop`
      font-size: 30px;
      margin-top: -23px;
    `}
    ${media.tabletteLandscape`
      font-size: 25px;
    `}
      ${media.iphoneSE`
      margin-top: -2px;
  ` }
    ${media.iphone13`
    margin-top: -2px;
  `}
  }
  span {
    color: ${colors.blue};
    font-weight: 300;
  }
  p {
    font-size: 2rem;
    font-weight: 200;
    ${media.laptop`
      font-size: 1.8rem;
    `}
    ${media.tabletteLandscape`
      font-size: 1.5rem;;
    `}
  }
  
`;
const Horaire = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6rem;
  gap: 10rem;
  ${media.laptop`
    gap: 6rem;
`}
  ${media.tabletteLandscape`
    gap: 4rem;
    padding: 6rem 4rem;
  `}
  ${media.tabletteVerticale`
    gap: 3rem;
    padding: 6rem 0rem;
  `}
  ${media.mobile`
    flex-direction: column;
    padding: 3rem 0rem;
  `}
  strong {
    font-size: 2.5rem;
    ${media.laptop`
      font-size: 2.2rem;
    `}
    ${media.tabletteLandscape`
      font-size: 2rem;;
    `}
  }
  p {
    font-size: 2rem;
    ${media.laptop`
      font-size: 1.5rem;
  `}
    ${media.mobile`
      flex-direction: column;
      padding: 1rem 0rem;
  `}
  }
`;

const Trait = styled.div`
  height: 100px;
  width: 1px;
  background-color: black;
  ${media.mobile`
    display: none;
  `}
`;

const BtnContainer = styled.div`
  margin-top: 5rem;
`;

const MoreInfosContainer = styled.div`
  display: flex;
  gap: 1rem;
  position: relative;
  margin: 1rem 0 1rem 3rem;
  top: 62rem;
  color: black;
  ${media.tabletteLandscape`
    top: 50rem;
    margin: 1rem 0 1rem 3rem;
  `}
  ${media.mobile`
    top: 60rem;
  `}
  i {
    color: black;
    ${media.tabletteLandscape`
      margin-top: 3px;
  `}
  }
    ${media.iphoneSE`
    top: 50rem;
  `}
  ${media.iphone13`
  top: 50rem;
`}
`;

const Opening = () => {
  return (
    <OpeningContainer id="horaires">
      <SectionTitleContainer>
        <SectionTitle title={'HORAIRES'} />
      </SectionTitleContainer>
      <InfoContainer>
        <HoraireSection>
          <h4>
            Votre salle vous accueille <span>tous les jours</span> toute l'année
          </h4>
          <Horaire>
            <p>
              <strong>En semaine :</strong> <br /> de 10h30 à 23h00
            </p>
            <Trait />
            <p>
              <strong>Le week-end:</strong> <br /> de 10h00 à 22h00
            </p>
          </Horaire>
          <p>
            Et pour s'y rendre, rien de plus facile, rendez-vous au <span>4 rue des linots</span>, ou consultez la rubrique "Nous trouver" pour plus de renseignements.
          </p>
          <BtnContainer>
            <Button text={'Nous trouver'} link="localisation" textcolor={colors.lightBlue} background={colors.blue} />
          </BtnContainer>
        </HoraireSection>
      </InfoContainer>
      <MoreInfosContainer>
        <Icon logo={'fa-solid fa-home'} hoverable={false} pointer={false} />
        <p>4 rue des linots, 13004 Marseille</p>
      </MoreInfosContainer>
      <MoreInfosContainer>
        <Icon logo={'fa-solid fa-phone'} hoverable={false} pointer={false} />
        <p> +33488224603</p>
      </MoreInfosContainer>
    </OpeningContainer>
  );
};

export default Opening;

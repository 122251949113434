import React from 'react';
import styled from 'styled-components';
import colors from '../../utils/variables';
import SectionTitle from '../widgets/SectionTitle';
import Duo from '../Duo';
import { media } from '../../utils/variables';

const StafContainer = styled.section`
  background: black;
  color: black;
  height: 110vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.mobile`
    height: 120vh;
`}
`;

const SectionTitleContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
  h3 {
    font-size: 240px;
    font-weight: 800;
    color: black;
    text-shadow: 2px 2px 0 ${colors.blue}, -2px -2px 0 ${colors.blue}, 2px -2px 0 ${colors.blue}, -2px 2px 0 ${colors.blue};
    ${media.laptop`
      font-size: 190px;
    `}
    ${media.tabletteLandscape`
      font-size: 150px;
    `}
    ${media.tabletteVerticale`
      font-size: 110px;
      margin-top: -100px;
    `}
    ${media.mobile`
      font-size: 60px;
    `}
    ${media.iphoneSE`
      margin-top: 3px;
    `}
    ${media.iphone13`
      margin-top: 3px;
    `}
   }
`;

const Staf = () => {
  return (
    <StafContainer>
      <SectionTitleContainer>
        <SectionTitle title={"L'ÉQUIPE"} />
      </SectionTitleContainer>
      <Duo />
    </StafContainer>
  );
};

export default Staf;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../../assets/smallLogoWhite.png';

const fadeInOut = keyframes`
  0% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const scaleInOut = keyframes`
  0%{
    transform: scale(1);
  }
  85% {
    transform: scale(1.5);
  }
  90% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.5);
  }
  
`;

const ContainerLoader = styled.div`
  width: 99vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999; 
  animation: ${fadeInOut} 2s ease-in-out forwards;
  overflow: hidden;
`;

const LogoImage = styled.img`
  width: 200px; 
  height: auto; 
  animation: ${scaleInOut} 2s forwards; 
`;

const Loader = () => {
  return (
    <ContainerLoader>
      <LogoImage src={logo} alt="Logo" />
    </ContainerLoader>
  );
};

export default Loader;

import React from 'react';
import styled from 'styled-components';
import colors, { media } from '../utils/variables';
import duo from '../assets/volume.jpg';

const StyledDuo = styled.section`
  display: flex;
  gap: 2rem;
  margin: 3rem;
  width: 80%;
  height: 600px;
  border-radius: 10px;
  ${media.tabletteVerticale`
    margin: 1rem;
`}
  ${media.mobile`
    flex-direction: column;
    gap: 1rem;
  `}
`;

const ImageContainer = styled.div`
  flex: 2; 
`;

const Styledimg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 10px;
`;

const TextContainer = styled.article`
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  strong{
    color: ${colors.blue}; 

  }
  p{
    color: ${colors.lightBlue}; 
    font-weight: 200;
    font-size: 1.3rem;
    text-align: justify;
    ${media.tabletteLandscape`
      font-size: 1.1rem;
  `}
  }
`;

const Duo = () => {
  return (
    <StyledDuo>
      <ImageContainer>
        <Styledimg src={duo} alt="Photo des frères"/>
      </ImageContainer>
      <TextContainer>
       <p>Nous sommes deux frères <strong>passionnés d'escalade</strong> depuis toujours, et c'est avec une immense <strong>joie</strong> que nous ouvrons notre salle d'escalade à <strong>Marseille</strong>. Notre <strong>expérience</strong> et notre amour pour ce sport nous ont poussés à créer un espace <strong>convivial</strong> et <strong>dynamique</strong> où chacun peut découvrir, apprendre et se <strong>perfectionner</strong>. Venez <strong>partager</strong> notre passion et <strong>grimper</strong> avec nous !</p>
      </TextContainer>
    </StyledDuo>
  );
};

export default Duo;

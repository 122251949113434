

import React, { useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import logo from '../../assets/Logo.png';

const Map = () => {
  useEffect(() => {
    // Création de la carte
    const map = L.map('mapid').setView([43.3136280, 5.4073421], 13); 

    // Ajout des tuiles de la carte OpenStreetMap
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    const customIcon = L.icon({
      iconUrl: logo,
      iconSize: [50, 50], // Taille de l'icône
      iconAnchor: [25, 25], // Point d'ancrage de l'icône
      popupAnchor: [0, -25] // Point d'ancrage de la popup
    });

    const marker = L.marker([43.3136280, 5.4073421], { icon: customIcon }).addTo(map);
    
    marker.bindPopup(`
    <div style="display: flex; align-items: center; height: 100px;">
    <img src="${logo}" width="50" height="50" style="margin-right: 10px;"/>
    <div>
      <p style="color: black;">4 rue des Linots, Marseille</p>
    </div>
  </div>
  
    `).openPopup();

    return () => {
      map.remove();
    };
  }, []); 

  return <div id="mapid" style={{ height: '400px', zIndex:"1"}} />;
};

export default Map;

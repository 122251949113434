import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import colors, { media } from '../../utils/variables';
import Button from './Button';

const InstagramPost = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: 1px solid black; 
`;

const PostMedia = styled.img`
  width: 40%;
  border-radius: 5px;
  margin-bottom: 10px;
  ${media.mobile`
  width: 60%;

  `}
`;

const PostVideo = styled.video`
  width: 20%;
  border-radius: 5px;
  margin: 2rem;
  ${media.mobile`
  width: 60%;

  `}
`;

const PostCaption = styled.p`
  font-size: 1.2em;
  color: ${colors.lightBlue};
  font-weight: 300;
  margin-bottom: 10px;
  text-align: center;
`;



const ErrorMessage = styled.div`
  color: red;
  font-size: 1.2em;
  text-align: center;
  margin-top: 20px;
`;

const LoadingMessage = styled.div`
  font-size: 1.2em;
  text-align: center;
  margin-top: 20px;
`;


const Insta = () => {
  const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLatestPost = async () => {
      try {
        const response = await axios.get(`https://graph.instagram.com/me/media`, {
          params: {
            fields: 'id,caption,media_type,media_url,permalink,timestamp,username',
            access_token: accessToken
          },
        });
        console.log(response);
        const latestPost = response.data.data[0];
        setPost(latestPost);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchLatestPost();
  }, [accessToken]);

  if (error) {
    return <ErrorMessage>Erreur: {error}</ErrorMessage>;
  }

  if (!post) {
    return <LoadingMessage>Chargement...</LoadingMessage>;
  }


    return (
      <InstagramPost>
        {post.media_type === 'IMAGE' || post.media_type === 'CAROUSEL_ALBUM' ? (
          <PostMedia src={post.media_url} alt={post.caption} />
        ) : (
          <PostVideo src={post.media_url} controls />
        )}
        <PostCaption>{post.caption}</PostCaption>
        <Button text={'Instagram'} link="https://instagram.com/45escalade" logo="fa-brands fa-instagram" />
      </InstagramPost>
    );
  };
  
  export default Insta;
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Insta from '../widgets/Insta';
import styled from 'styled-components';
import { media } from '../../utils/variables';

const Container = styled.div`
 h1{
  text-align: center;
  font-size: 50px;
  margin: 1rem;
  ${media.mobile`
  font-size: 30px;
  `}
 }
 justify-content: center;
`;

const Actus = () => {
  return (
    <>
    <Helmet>
    <title>Actus</title>
    <meta name="description" content="Tenez vous informé de nos prochain événements."></meta>
    <link rel='canonical' href='/actus'></link>
  </Helmet>

    <Container id='actus'>
      <h1>Nos dernières actualités</h1>
      <Insta/>
    </Container>
    </>
  );
};

export default Actus;
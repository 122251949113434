import React from 'react';
import { StyledContainer } from './Ri';

const Cgv = () => {
  return (
    <StyledContainer>
      <h1>Conditions Générales de Vente</h1>

<h3>Préambule</h3>
<p>Les présentes conditions générales (ci-après « CGV ») s’appliquent de plein droit à toute vente de prestations fournies dans la salle d’escalade 45° Escalade, conclue entre le client (ci-après « l’Adhérent ») et la société 45 Escalade (ci-après la « Société »). Toute vente implique l’adhésion préalable de l’Adhérent aux présentes CGV. L’Adhérent reconnaît ainsi en avoir pris connaissance avant de passer commande et les accepter sans réserve dans leur intégralité. Les CGV applicables sont celles en vigueur le jour de la vente. Elles annulent et remplacent dans toutes leurs dispositions celles qui auraient pu être précédemment acceptées par l’Adhérent. La Société se réserve la possibilité d’adapter ou de modifier à tout moment les présentes CGV et de les publier sur son site internet.</p>

<h2>1. L’escalade</h2>

<h3>1. Les entrées</h3>
<p>Le client peut acheter une entrée à l’unité ou un carnet de 10 entrées, utilisable sans limite de temps. Le règlement est effectué dans son intégralité, le jour de la commande. A compter de son règlement, la souscription devient ferme, définitive et non résiliable.</p>

<h3>2. Les abonnements</h3>
<p>Les abonnements annuels sont souscrits pour une durée de douze mois. Les abonnements mensuels sont souscrits pour une durée de trente (30) ou trente-un (31) jours tacitement reconductibles.</p>

<h3>3. Les cours</h3>
<p>Des cours d’escalade encadrés sont proposés aux adultes et aux enfants en période scolaire, selon la durée, les horaires, tarifs et modalités affichés à l’accueil ou sur le site internet de 45 Escalade. Les cours et stages à destination des enfants sont proposés par catégorie d’âge. Sauf accord préalable de la Société, le client doit avoir l’âge requis pour les cours auxquels il est inscrit, au jour du premier cours, sous peine de résiliation de plein droit de l’inscription par la Société. Le client ne peut pas modifier le créneau de cours ou de stage auquel il s’est inscrit.</p>
<p>Les cours et les stages sont réglés en intégralité au jour de l’inscription. A compter du règlement, l’inscription du client devient ferme, définitive et non résiliable. Les cours annuels (10 mois) peuvent être réglés en 10 règlements mensuels, sous réserve que l’Adhérent remplisse un mandat de prélèvement SEPA accompagné d’un RIB. Le règlement de la première mensualité interviendra alors au jour de l’inscription. L’inscription devient ferme et définitive au jour du règlement de sa première mensualité, sans résiliation possible avant son échéance. En cas de comportement inadéquat de l'enfant, notamment le non-respect du RI, la Société se réserve le droit de procéder au renvoi de l'enfant, sans possibilité de remboursement.</p>

<h2>2. Conditions d’accès</h2>

<h3>2.1</h3>
<p>Les horaires d’ouverture des Salles sont affichés à l’accueil ou sur leur site internet. Les éventuelles modifications seront portées à la connaissance des adhérents (ex : horaires d’été, jours fériés, etc.) mais elles n’ouvrent droit à aucune indemnité.</p>

<h3>2.2</h3>
<p>L’accès aux salles se fait par une carte personnelle, nominative et incessible qui est remise lors de la commande du client. Cette carte est payante. En cas de perte ou de vol, l’Adhérent s’engage à en informer sans délai la Société. Une nouvelle carte lui sera remise contre le versement d’une nouvelle somme. Le client doit obligatoirement passer sa carte dans une borne située à l’entrée de la Salle, avant de pouvoir y accéder. Il est strictement interdit de la prêter à un tiers.</p>

<h3>2.3</h3>
<p>Le port de chaussons d’escalade est obligatoire pour accéder à l’Espace Escalade. A défaut, le client peut se voir refuser son accès. 45 Escalade dispose d’un service de location de chaussons d’escalade auquel le client peut recourir.</p>

<h3>2.4</h3>
<p>Tout matériel loué ou prêté doit être restitué en parfait état en fin de séance ou à l’issue de la période de prêt ou de location. Tout matériel endommagé ou non restitué sera facturé au client.</p>

<h3>2.5</h3>
<p>Des casiers sont mis à la disposition des clients afin qu’ils puissent y laisser leurs effets personnels le temps de leur séance. Les clients les utilisent de manière autonome. Ils doivent apporter leur propre cadenas pour les verrouiller. Ils veilleront à ce que le cadenas utilisé dispose d’un système de sécurité suffisant pour éviter tout vol et à ce que le casier soit bien verrouillé avant toute séance. Le client veillera en outre à ne laisser aucun objet de valeur dans le casier. Il devra impérativement laisser le casier libre à l’issue de sa séance. 45 Escalade n’est pas responsable en cas de vol ou de perte d’objet du client.</p>

<h3>2.6</h3>
<p>En plus des présentes CGV et du RI, le client s’engage à respecter strictement l’ensemble des règles et consignes, notamment en matière d’hygiène et de sécurité, qui pourraient lui être données à l’oral ou par voie d’affichage par le personnel de la Salle. Le client reconnaît à la direction de la Salle le droit d’exclure de l’établissement toute personne dont l’attitude, le comportement ou la tenue seraient de nature à porter atteinte à la sécurité des autres membres ou qui constitueraient une violation des lois pénales (vol, violence, atteinte aux biens ou aux personnes, …) ou une violation du RI ou des consignes données par le personnel de la Salle.</p>

<h2>3. Résiliation</h2>

<h3>3.1 Résiliation à l’initiative de la Société</h3>
<p>Le contrat est résilié de plein droit par la Société, aux motifs suivants : a) en cas de violation des lois pénales et celles protégeant les droits des tiers, sans préavis ni mise en demeure préalable ; b) en cas de non-respect des présentes CGV ou du RI, quinze jours après mise en demeure adressée, indiquant l’intention de faire jouer cette clause, restée infructueuse. Durant cette période, le client disposera d’un droit de réponse sur les faits qui lui sont reprochés. A l'issue de ce délai, le Contrat sera soit résilié soit prorogé de 15 jours. c) en cas de défaut de paiement : un incident de paiement donne lieu à la suspension de la carte d’accès jusqu’à régularisation ; deux défauts de paiements ou plus, consécutifs ou non, pourront donner lieu à la résiliation du contrat, après suspension de la carte d’accès, dans les quinze jours suivants mise en demeure indiquant l’intention de faire jouer cette clause, et restée infructueuse. Toute somme versée par le client restera acquise à la société. Ces résiliations ne font en outre pas obstacle à la faculté de la Société d’engager toutes poursuites qu’elle estimerait utiles, et notamment de poursuivre le recouvrement des sommes restant dues par le client jusqu’à l’échéance de son Contrat. Le Contrat pourra également être résilié par la Société, en cas de fermeture définitive de la salle.</p>

<h3>3.2 Résiliation à l’initiative du client</h3>
<p>Les carnets d’entrées, de 1 à 10 séances ou la billetterie ne sont pas remboursables. Les autres Prestations sont souscrites pour une durée ferme et définitive lors de leur souscription et ne peuvent pas être résiliés avant leur terme, sauf, et sous réserve de la présentation de justificatifs valides (certificats médicaux, attestations employeurs…), pour des raisons médicales, en cas de grossesse ou d’incapacité d’exercer toute activité sportive pendant plus 6 mois. L’abonnement mensuel peut être résilié à tout moment sur demande écrite, ou par mail, à l’adresse contact@45escalade.com.</p>

<h3>3.3 Suspension</h3>
<p>Sur présentation d’un certificat médical valide attestant d’une incapacité ponctuelle, égale ou supérieure à un mois et inférieure à six mois, du client à user de son abonnement, celui-ci pourra être suspendu ou prolongé de la durée indiquée par tranche de un mois sur le certificat médical.</p>

<h2>4. Aptitude physique</h2>
<p>La pratique d'un sport s'accompagne de risques. Le client doit pouvoir évaluer ce dont il est capable. Il est responsable de la manière dont il pratique le sport et des choix qu’il fait à cet égard. Il prend ainsi toutes les précautions requises afin d’assurer sa propre sécurité ou celle du mineur dont il est le représentant légal et veillera au respect par lui, ou par le mineur, de toutes les règles de sécurité qui lui sont communiquées. L’Adhérent atteste toutefois que sa constitution physique et son état de santé, ou ceux du mineur dont il est le représentant légal, lui/leur permettent de pratiquer le sport en général, et plus particulièrement l’escalade de bloc où les réceptions sur les tapis sont inévitables.</p>

<h2>5. Droit à l’image</h2>
<p>Pour valider son inscription, la Société peut être amenée à demander une photo d'identité ou à prendre une photo du client. Cette Photo a uniquement comme but de vérifier l'identité du client à l’entrée de la salle. L’adhérent accepte et autorise par la présente cette prise de vue ainsi que son utilisation par la Société.</p>

<h2>6. Données personnelles</h2>
<p>Les données à caractère personnel que le client a communiqué à la Société (tels que nom, photo, prénom, numéro de téléphone, adresse mail) sont collectées et traitées par la Société pour lui permettre d’exécuter l’ensemble des Prestations. Les données ainsi recueillies sont traitées conformément à la Politique de protection des données personnelles.</p>

    </StyledContainer>
  );
};

export default Cgv;
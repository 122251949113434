import React from 'react';
import colors, { media } from '../../utils/variables';
import styled, { css } from 'styled-components';

const StyledIcon = styled.i`
  font-size: 1.5rem;
  display: flex;
  text-decoration: none;
  gap: 1rem;
  color: ${colors.lightBlue};
  ${media.laptop`
    font-size: 1.3rem;
  `}
  ${media.tabletteLandscape`
    font-size: 1rem;
  `}

  ${(props) =>
    props.hoverable &&
    css`
      &:hover {
        color: ${colors.blue};
        transform: scale(1.2);
        transition: transform 0.2s;
      }
    `}
  ${(props) =>
    props.pointer === false &&
    css`
      cursor: default;
    `}
`;

const Icon = ({ logo, link, hoverable = true, pointer = true }) => {
  return (
    <StyledIcon as={link ? 'a' : 'div'} href={link} target="_blank" rel="noopener noreferrer" hoverable={hoverable} pointer={pointer}>
      <i className={logo}></i>
    </StyledIcon>
  );
};

export default Icon;

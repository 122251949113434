import React from 'react';
import styled from 'styled-components';

export const StyledContainer = styled.div`
padding: 2rem;
li{
  font-size: 20px;
  font-weight: 100;
  color: #d8e8f2;
}

h1{
  text-align: center;
  font-size: 40px;
}
h2{
  font-size: 30px;
  padding: 1rem;
  font-weight: 600;
}
h3{
  font-size: 25px;
  padding: 1rem;
  font-weight: 300;
}
`;

const Ri = () => {
  return (
    <StyledContainer>
      <h1>Règlement intérieur</h1>
      <h2>1. Objet</h2>
      <p>
        Ce règlement intérieur (ci-après le « Règlement Intérieur ») a pour objet de préciser les conditions de fonctionnement, de pratique et de sécurité qui s’imposeront à l’ensemble des
        utilisateurs.
      </p>
      <h2>2. Accès</h2>
      <p>Toute personne doit impérativement se présenter à l’accueil et créer un profil de membre pour pouvoir accéder aux installations. Un pointage sera requis à chaque nouvelle entrée dans la salle. De façon générale, respecter le Règlement Intérieur et se conformer aux consignes même verbales de l’équipe.</p>

<p>Lors de la première visite :</p>
<ul>
    <li>Remplir une fiche de renseignements ;</li>
    <li>Prendre connaissance du présent règlement ;</li>
    <li>Prendre connaissance des consignes de sécurité ;</li>
    <li>S’acquitter du droit d’entrée.</li>
</ul>

<p>Concernant les mineurs :</p>
<ul>
    <li>Les mineurs de moins de 8 ans ne sont pas autorisés à pratiquer l’escalade ;</li>
    <li>Les mineurs de plus de 8 ans et de moins de 14 ans révolus ne sont pas autorisés à pratiquer l’escalade. Ils doivent être obligatoirement accompagnés de leurs parents ou d’un représentant légal et demeurés sous leur surveillance pendant l’ensemble de la pratique de l’escalade. En l’absence d’un majeur responsable, les mineurs de moins de 14 ans ne pourront pratiquer l’escalade qu’encadrés par nos moniteurs diplômés dans le cadre d’un cours collectif ou particulier ;</li>
    <li>Les mineurs de 14 ans et plus disposent de la possibilité de pratiquer l’escalade en autonomie lorsque ses représentants légaux ont rempli une autorisation parentale.</li>
    <li>Un adulte ou représentant légal ne peut accompagner que trois mineurs en même temps. Au-delà de cette limite, un accompagnant supplémentaire sera nécessaire.</li>
</ul>

<h2>3.     Horaires, séances et tarifs</h2>
<h3>a.     Individuels</h3> 
<p>Les horaires et les tarifs en vigueur sont affichés à l’accueil et sur le site
internet. 45 Escalade se réserve le droit en fonction des besoins
d’exploitation (stages, cours, compétitions, ouvertures de voies, travaux.) de
modifier les horaires et/ou d’immobiliser une partie de la salle. En cas de
fermeture exceptionnelle pour les raisons citées ci-dessus ou autres, 45
Escalade s’engage dans la mesure du possible à informer préalablement
les utilisateurs.</p>
<h3>b. Groupes</h3>
<p>Dans le cadre des horaires grand public (affichés à l’accueil), aucun groupe
ne pourra accéder aux installations sans réservation. En dehors des horaires
grand public, l’accès aux installations est possible sous réserve de
l’établissement d’un contrat où seront précisées notamment les conditions
d’accès, de tarif et d’encadrement.</p>
<h3>c. Encadrement</h3>
<p>Seuls les moniteurs de 45 escalade peuvent enseigner et encadrer, sauf
dérogation accordée par le responsable de la salle.</p>
<h2>4.     Utilisation des installations et des
structures de la salle</h2>
<p>Le respect du matériel et des personnes devra être un souci permanent
chez les grimpeurs et l’encadrement 45 escalade. La politesse, la courtoisie
et la bienséance sont nécessaires à toute ambiance conviviale. 45 escalade
y est profondément attaché.
C’est à ce titre que nous vous rappelons les règles de fonctionnement
suivantes :</p>
<h3>a.     Magnésie</h3>
<p>L’utilisation de magnésie en poudre est strictement interdite dans
l’ensemble de la salle de bloc. Tout utilisateur est invité à utiliser la
magnésie liquide.</p>
<h3>b. Vestiaires</h3>
<p>L’utilisation des vestiaires est obligatoire pour se changer et déposer ses effets personnels. Des casiers sont à votre disposition, leur fermeture par l’intermédiaire d’un cadenas est vivement conseillée. Les effets personnels ne peuvent être laissés à l’intérieur des casiers des vestiaires une fois que le client a quitté la salle. Les cadenas restants sur les casiers après la fermeture de l’établissement seront coupés.</p>
<p>Il est rappelé au client que les vestiaires ne font l’objet d’aucune surveillance spécifique. Le client reconnaît donc avoir été informé des risques encourus par le fait de placer des objets de valeur dans les vestiaires, ce qui en aucune façon ne peut être interprété comme étant un dépôt. 45 escalade n’est pas responsable des objets perdus, volés ou détériorés entreposés dans les vestiaires. Les affaires personnelles laissées en dehors des vestiaires ou des casiers prévus à cet effet ne sont pas autorisées.</p>

<h3>c. Toilettes et douches</h3>
<p>Toute personne utilisant ceux-ci est priée de maintenir la propreté des lieux mis à disposition.</p>

<h3>d. Espace musculation</h3>
<p>L’espace musculation est libre d’accès en dehors des cours collectifs. Le matériel est utilisable par les clients à partir de 16 ans révolus et sous réserve de ne pas le dégrader. Le matériel doit être (i) rangé après utilisation dans le meuble prévu à cet effet, (ii) manipulé avec précaution ainsi que dans le respect des consignes d’utilisation, (iii) utilisé uniquement dans le cadre des activités prévues, (iv) nettoyé après chaque utilisation et (v) équitablement partagé entre les personnes souhaitant l’utiliser.</p>
<p>Tout dommage causé au matériel en raison d’une utilisation inappropriée ou abusive pourra entraîner la facturation de frais de réparation ou de remplacement à la charge de la personne à l’origine du dommage.</p>

<h3>e. Nourriture et boissons</h3>
<p>La nourriture et les boissons sont interdites sur les tapis de réception. L’espace bar-restauration est réservé à cet effet. Seules les gourdes et des bouteilles plastiques fermées seront tolérées au pied des blocs.</p>
<p>Aucune boisson alcoolisée ne peut être vendue à un mineur. Les boissons alcoolisées sont vendues en accessoire d’un repas.</p>

<h3>f. Tenue</h3>
<p>Une tenue et une attitude correcte sont exigées. Le port du T-shirt est obligatoire. Il est interdit de se déplacer pieds nus dans la salle. Le lavage de mains avant et après chaque séance est obligatoire. Les consignes sanitaires doivent être respectées (lavage des mains etc.)</p>

<h3>g. Règles de sécurité</h3>
<p>Le libre accès aux installations de l’établissement est subordonné à la maîtrise des techniques de base de sécurité permettant d’être autonome. Les règles de sécurité sont présentées oralement à tout nouvelle utilisateur. La bonne prise des consignes pour chaque nouveau membre de la salle est obligatoire.</p>
<p>Tout client ne maîtrisant pas les techniques de base de sécurité doit le signaler à l’un des membres du personnel de l’établissement afin de se les faire expliquer ou s’inscrire à un cours découverte. Tout client ne maîtrisant pas les techniques de base de sécurité devra obligatoirement être encadré par une personne majeure les maîtrisant.</p>

<h2>6. Divers</h2>

<h3>a. Assurance</h3>
<p>Les garanties au titre des Accidents Corporels n’étant pas incluses dans le prix de l’accès à l’installation, il appartient à tout public d’examiner sa couverture personnelle, notamment en cas de blessures ou d’invalidité, et de la compléter à titre personnel par la souscription d’un contrat auprès de son propre assureur. 45 escalade est assuré pour les dommages engageant sa responsabilité civile et celle de son personnel conformément au code du sport.</p>

<h3>b. Santé</h3>
<p>L’utilisateur atteste que sa constitution physique et son état de santé lui permettent de pratiquer le sport en général, et plus particulièrement d’utiliser les activités, le matériel et les installations proposées par 45 escalade.</p>

<h3>c. Dégradation</h3>
<p>Les utilisateurs sont considérés comme responsables de toutes dégradations qui pourraient être causées de leur fait, aux installations, aux matériels, aux vestiaires et autres lieux, quels qu’ils soient. 45 escalade se réserve le droit de facturer les réparations au(x) responsable(s) des dégradations.</p>

<h3>d. Sanctions</h3>
<p>En cas de non-respect du Règlement Intérieur ou de toutes consignes même verbales de l’encadrement, 45 escalade se réserve le droit d’appliquer les sanctions suivantes : suspension, expulsion, radiation. Dans le cadre de ces sanctions, le contrevenant ne pourra prétendre récupérer le montant de sa séance ou de son abonnement.</p>

<h3>e. Modification</h3>
<p>45 escalade se réserve le droit de modifier le Règlement Intérieur même sans préavis.</p>

<h3>f. Respect du Règlement Intérieur</h3>
<p>45 escalade décline toute responsabilité en cas d’accident survenu à la suite de la non-observation du présent règlement, et de l’utilisation inappropriée des appareils et installations proposés.</p>

   </StyledContainer>
  );
};

export default Ri;
